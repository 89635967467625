import {
  Box,
  Container,
  makeStyles,
  Typography
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import LanguageIcon from '@material-ui/icons/Language'
import MemoryIcon from '@material-ui/icons/Memory'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import { useStaticQuery } from 'gatsby'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import React, { useEffect, useState } from 'react'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import '../components/custom.css'
import Firebase from '../components/Firebase'
import GalleryCardHomePage from '../components/GalleryCardHomePage'
import Layout from '../components/layout'
import '../components/layout.css'
import SEO from '../components/seo'
import SlickSlider from '../components/SlickSlider'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  whiteColor: {
    color: '#FFFFFF',
  },
  largeFont: {
    fontSize: 54,
    color: '#2196f3',
  },
  overlay: {
    zIndex: 1,
    height: '100%',
    width: '100%',
    overflow: 'auto',
    background: 'rgba(0, 0, 0, 1)',
  },
  marginTop84: {
    marginTop: 84,
  },
  shadow: {
    backgroundColor: '#eed',
    boxShadow: '0 0 15px rgba(0,0,0,0.6)',
    MozBoxShadow: '0 0 15px rgba(0,0,0,0.6)',
    WebkitBoxShadow: '0 0 15px rgba(0,0,0,0.6)',
    OBoxShadow: '0 0 15px rgba(0,0,0,0.6)',
  },
  titleFont: {
    fontFamily: 'Volkhov',
    fontSize: 44,
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
}))

const Home = props => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])
  useEffect(() => {
    setLoading(true)
    Firebase.firestore()
      .collection('tweets')
      .get()
      .then(function (snapshot) {
        setLoading(false)
        setRows([])
        snapshot.forEach(function (doc) {
          setRows(old => [...old, doc.data()])
        })
      })
      .catch(function (error) {
        setLoading(false)
      })
  }, [])

  const data = useStaticQuery(graphql`
    query {
      first: file(relativePath: { eq: "meeting.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality:100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      second: file(relativePath: { eq: "office2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality:100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      third: file(relativePath: { eq: "india.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality:100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      webmail: file(relativePath: { eq: "webmail_p.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality:100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vpn: file(relativePath: { eq: "vpn_p.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      radio: file(relativePath: { eq: "radio_p.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div>
      <Layout>
        <SEO title="Colloidle systems" />
        {/* <Slider slides={images} autoPlay={3} /> */}
        <SlickSlider />
        <Container>
          <section className={classes.marginTop84}>
            <Typography variant="h4" className={classes.titleFont}>
              About Colloidle
            </Typography>

            <Typography variant="h6" style={{ marginTop: 24 }} >
              Colloide System was formed with a core objective of providing an exceptional level of client servicing and product building. We have built our team and have gained tremendous experience over the last few years to become one of the leading web design company in India. This formidable mix has allowed us to scale up rapidly and handle challenging projects for multiple industry verticals.
            </Typography>

            <Grid
              spacing={2}
              style={{ marginTop: 84 }}
              container
              direction="row"
              alignItems="center"
              justify="center"
              align="center"
            >
              <Grid item md={4} xs={12}>
                <GalleryCardHomePage
                  id={1}
                  image={data.first.childImageSharp.fluid}
                  title="Great team, great company"
                  desc="The great company is build on the foundation of the great team, We are the technology enthusiast group om people formed this company. We are at colloidle tries to find the solutions for the common man problem and build products for them."
                />
              </Grid>

              {/* <Grid item md={4} xs={12}>
                <GalleryCardHomePage
                  id={2}
                  image={data.second.childImageSharp.fluid}
                  title="Additional Charge of PCCIT"
                  desc="Additional Charge of PCCIT Kerala Region to Shri Ravindra Kumar, IRS (86005) -reg.."
                />
              </Grid> */}

              <Grid item md={4} xs={12}>
                <GalleryCardHomePage
                  id={3}
                  image={data.third.childImageSharp.fluid}
                  title="Vision and focus"
                  desc="Our vision is to connect the people across the glob and solve the communication problems by building great and useful products in various platforms which included mobile application, web application and electronics platform for the society"
                />
              </Grid>
            </Grid>
          </section>
        </Container>


        <Box
          spacing={8}
          className={classes.marginTop84}
          style={{ backgroundColor: '#efefef' }}
        >
          <Container style={{ padding: 54 }}>
            <Typography variant="h4" className={classes.titleFont}>
              Products
            </Typography>
            <Grid style={{ marginTop: 64 }}
              container
              spacing={2}
              direction="row"
              alignItems="center"
              justify="center"
              align="center"
            >

              <Grid item md={4} xs={12}>
                <GalleryCardHomePage
                  id={1}
                  image={data.webmail.childImageSharp.fluid}
                  title="Webmail App"
                  desc="Webmail app is build for those who dont know how to confiture the mail server in there mobile. This app will automatically detect the configuration for your mail server and easily enable you to access and send your emails."
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <GalleryCardHomePage
                  id={1}
                  image={data.vpn.childImageSharp.fluid}
                  title="Free open vpn"
                  desc="Free Open VPN is a free and open-source software application that implements virtual private network techniques to create secure point-to-point connections in routed or bridged configurations and remote access facilities."
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <GalleryCardHomePage
                  id={1}
                  image={data.radio.childImageSharp.fluid}
                  title="Radio world"
                  desc="Radio world is facilitate you to hear the radios from all over the world. On the occasion of World Radio Day small contribution from us to the world. Radio World contains the variety of channels in different languages across the world."
                />
              </Grid>
              {/* {rows.map((v, k) => (
                <Grid item md={4} xs={12} key={k}>
                  <Paper
                    raised
                    style={{ padding: 24, maxWidth: 350 }}
                    align="left"
                  >
                    <img
                      src={twitter}
                      width="15%"
                      height="15%"
                      alt="IRS Association"
                    />
                    <Typography
                      style={{ lineHeight: 2, marginTop: 34 }}
                      variant="body1"
                    >
                      {v.tweet.length > 250
                        ? v.tweet.substring(0, 250)
                        : v.tweet}
                      <Button
                        target="_blank"
                        style={{ textTransform: 'none' }}
                        href={`https://twitter.com/${v.author}`}
                        color="primary"
                      >
                        {v.author}
                      </Button>
                    </Typography>
                    <Typography
                      style={{ lineHeight: 2, marginTop: 34 }}
                      variant="body2"
                    >
                      {v.publishedOn}
                      <Button
                        style={{ textTransform: 'none' }}
                        color="primary"
                        href={v.shortLink}
                        target="_blank"
                      >
                        Visit Twitter
                      </Button>
                    </Typography>
                  </Paper>
                </Grid>
              ))} */}
            </Grid>
          </Container>
        </Box>
        <Container>
          <section className={classes.marginTop84}>
            <Typography variant="h4" className={classes.titleFont}>
              Services
            </Typography>

            <Grid
              spacing={2}
              style={{ marginTop: 44 }}
              container
              direction="row"
            >
              <Grid item md={4} xs={12}>
                <LanguageIcon fontSize="large" />
                <Typography variant="h6">Web applications</Typography>
                <Typography variant="body2" style={{ marginTop: 24 }}>We are the web application company best in the market. Our expert team is capable of building web application which will be responsive and delivered on time</Typography>
              </Grid>
              <Grid item md={4} xs={12}>
                <PhoneAndroidIcon fontSize="large" />
                <Typography variant="h6">Mobile applications</Typography>
                <Typography variant="body2" style={{ marginTop: 24 }}>We have having experience in building mobile applications for both android and ios platform. We also have clients for whom we build applicatoin in the hybrid platform like flutter.</Typography>
              </Grid>
              <Grid item md={4} xs={12}>
                <MemoryIcon fontSize="large" />
                <Typography variant="h6">Embedded systems</Typography>
                <Typography variant="body2" style={{ marginTop: 24 }}></Typography>
                <Typography variant="body2" style={{ marginTop: 24 }}>We have the seperate team which is working on developing embedded solutions in comminucation and home automation field. We also build the solutions as per the requirement.</Typography>
              </Grid>

            </Grid>
          </section>
        </Container>


        <div
          className={classes.marginTop84}
          style={{ backgroundColor: '#2a4274' }}
        >
        </div>
      </Layout>
    </div >
  )
}

export default Home
