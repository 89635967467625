import {
  makeStyles,

  Typography,


  useMediaQuery
} from '@material-ui/core'
import React from 'react'
import Slider from 'react-slick'
import Flag from '../images/colloidlefirst.jpg'
import ThirdImage from '../images/services_header_c.webp'
import SecondImage from '../images/team_c.webp'
import './layout.css'
import './social.css'

const useStyles = makeStyles(theme => ({
  firstSlide: {
    position: 'relative',
    margin: 'auto',
    fontFamily: 'Volkhov',
    width: '100%',
    height: '100vh',
    backgroundImage: `url(${Flag})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  secondSlide: {
    position: 'relative',
    margin: 'auto',
    fontFamily: 'Volkhov',
    width: '100%',
    height: '100vh',
    backgroundImage: `url(${SecondImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  thirdSlide: {
    position: 'relative',
    margin: 'auto',
    fontFamily: 'Volkhov',
    width: '100%',
    height: '100vh',
    backgroundImage: `url(${ThirdImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  bottom: {
    bottom: '5%',
    left: '10%',
    [theme.breakpoints.down('sm')]: {
      bottom: '15%',
      left: '17%',
    },
  },

  font: {
    fontFamily: 'Volkhov',
  },
  title: {
    fontFamily: 'Volkhov',
    fontSize: 44,
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
    color: '#FFF',
  },
  subTitle: {
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    marginTop: 24,
    color: '#FFF',
  },
  button: {
    marginTop: 24,
    fontSize: 20,
  },

  iconbar: {
    position: 'absolute',
    top: '50%',
    webkitTransform: 'translateY(-50%)',
    msTransform: 'translateY(-50%)',
    transform: 'translateY(-50%)',
  },
}))

const SlickSlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    swipe: true,
    arrows: true,
  }

  const matches = useMediaQuery('(min-width:1024px)')

  const classes = useStyles()
  return (
    <div>
      <Slider {...settings}>
        <div>
          <div className={classes.firstSlide}>
            <div
              variant="h2"
              className="slideTitle"
            // style={{
            //   background: 'linear-gradient(90deg,black 0%,transparent 100%)',
            // }}
            >
              <div
                className={classes.bottom}
              >
                <Typography
                  variant="h2"
                  align="center"
                  className={classes.title}
                >
                  Communication
                </Typography>
                {/* <br />
                <br />
                <br />
                <Typography
                  variant="h6"
                  align="left"
                  style={{ float: 'center', color: '#FFF' }}
                >
                  Indian Revenue Service
                </Typography>
                <br /> */}
                {/* <br /> */}
                {/* <hr
                  style={{
                    float: 'center',
                    width: '10%',
                    height: '5px',
                    background: '#FFF',
                    marginTop: 24,
                  }}
                /> */}
                <br />
                <br />
                <Typography
                  variant="body2"
                  className={classes.subTitle}
                  align="center"
                  style={{ float: 'left', marginTop: 8, fontSize: '35px' }}
                >
                  We are the company who are building the solutions in{' '}
                  <br />
                  the commuication domain including from mobile apps  <br /> to web applications.
                  <br />
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className={classes.secondSlide}>
            <div
              variant="h2"
              className="slideTitle"
            // style={{
            //   background: 'linear-gradient(90deg,black 0%,transparent 100%)',
            // }}
            >
              <div
                className={classes.bottom}
              >
                <Typography
                  variant="h2"
                  align="center"
                  className={classes.title}
                >
                  Great team
                </Typography>
                {/* <br />
                <br />
                <br />
                <Typography
                  variant="h6"
                  align="center"
                  style={{ float: 'center', color: '#FFF' }}
                >
                  Indian Revenue Service
                </Typography> */}
                {/* <br /> */}
                {/* <br /> */}
                {/* <hr
                  style={{
                    float: 'left',
                    width: '10%',
                    height: '5px',
                    background: '#FFF',
                    marginTop: 24,
                  }}
                /> */}
                <br />  <br /><br />
                <Typography
                  variant="body2"
                  className={classes.subTitle}
                  align="center"
                  style={{ float: 'left', marginTop: 8, fontSize: '35px' }}
                >
                  We have a greate team having experience –{' '}
                  <br />
                  in web, mobile, hybrid and electronics
                  <br />
                  domain
                  <br />
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className={classes.thirdSlide}>
            <div
              variant="h2"
              className="slideTitle"
            // style={{
            //   background: 'linear-gradient(90deg,black 0%,transparent 100%)',
            // }}
            >
              <div
                className={classes.bottom}
              >
                <Typography
                  variant="h2"
                  align="center"
                  className={classes.title}
                >
                  Services
                </Typography>
                <br />
                <br />
                <br />
                {/* <Typography
                  variant="h6"
                  align="left"
                  style={{ float: 'left', color: '#FFF' }}
                >
                  Indian Revenue Service
                </Typography>
                <br />
                <br />
                <hr
                  style={{
                    float: 'left',
                    width: '10%',
                    height: '5px',
                    background: '#FFF',
                    marginTop: 24,
                  }}
                />
                <br />
                <br /> */}
                <Typography
                  variant="body2"
                  className={classes.subTitle}
                  align="center"
                  style={{ float: 'left', marginTop: 8, fontSize: '35px' }}
                >
                  As we are working in various domain  –{' '}
                  <br />
                  we also build the custom software as per the requirement
                  <br />
                  along with our own products
                  <br />
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Slider>

    </div>
  )
}

export default SlickSlider
