import {
  Card,
  CardActionArea,
  CardContent,
  Typography
} from '@material-ui/core'
import Img from 'gatsby-image'
import React from 'react'


const GalleryCardHomePage = props => {

  return (
    <Card raised style={{ maxWidth: 350 }}>
      <CardActionArea>
        {/* <img
          src={props.image}
          title="Contemplative Reptile"
          height="10%"
          alt="IRS Association"
        /> */}
        <Img fluid={props.image} />
        <CardContent align="left">
          <Typography gutterBottom variant="h6" component="h2" color="primary">
            {props.title}
          </Typography>
          <Typography variant="body2" component="p">
            {props.desc}
          </Typography>
          <Typography variant="caption" style={{ marginTop: 8 }}>
            {props.date}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default GalleryCardHomePage
